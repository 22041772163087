import React, { useState } from 'react';
import {
  AccordionContainer,
  AccordionItem,
  Answer,
  Cross,
  Icon,
  Number,
  Plus,
  Question,
  Text,
} from './Accordion.styled';

const Accordion = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = index => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqItems = [
    {
      question: 'Можливо подивитись товар перед придбанням?',
      answer: `Так можна подивитись у нас на складі, або попросити відеоогляд. Ми з радістю його надамо.`,
    },
    {
      question: 'Як довго йде замовлення?',
      answer:
        'Ми відправляємо поштою, зазвичай 1-2дня в залежності від того, де ви знаходитесь ',
    },
    {
      question: 'Як мені оплатити замовлення? ',
      answer: 'Оплата будь-яким зручним для Вас способом.',
    },
    {
      question:
        'Як в мене гарантії, що я отримаю товар оплативши його повністю?',
      answer:
        'У нас є склад і товар який ми продаємо. До нас можна приїхати на базу. Також ми є у всіх соціальних мережах. Наш зміст роботи працювати на постійній основі, щоб реалізувати великі обьєми товару. ',
    },
    {
      question: 'Яке мінімальне замовлення?',
      answer:
        'Мінімальне замовлення 1 мішок, Вага мішків різна в залежності від типу товару. Мішки йдуть від 15кг.',
    },
    {
      question: 'Що таке Оригінал?',
      answer:
        'Оригінал секонд хенд – це речі, які не сортувались. Ця категорія секонду має низьку ціну. Специфіка цього виробництва полягає в тому, що в одному мішку, можна знайти найрізноманітніші речі, різних категорій товару.',
    },
    {
      question: 'Що таке Сортовка?',
      answer: (
        <>
          Сортований секонд-хенд отримують за допомогою перебора на фабриках
          оригіналу на окремі позиції: футболки, плаття, шорти, взуття, сумки і
          т.д. Крім видів одягу сортують також за сортністю в залежності від
          розміру речі, її стану.
          <br /> <br />
          Сортований секонд-хенд як правило дорожче оригіналу, але Ви отримуєте
          безпосередньо той вид одягу і той сорт, який Вам необхідний у даний
          момент.
          <br /> <br /> Сортування дозволяє наповнити магазин речами, що
          користуються запитом в даний момент в залежності від сезана, можливо у
          Вашому регіоні є запит на ту або інші позиції, які рідко потрапляють в
          оригінальне або потрапляють в недостатній кількості. Наприклад, у Ваш
          магазин часто заходять будівельники або працівник різних
          спеціальностей і Вам потрібний робочий одяг. Можливо магазин
          розташований поруч із військовою частиною і військове обмундування
          буде користуватися запитом. Літом великим запитом користуються
          бейсболки, футболки, шорти, купальники та інші літні позиції. Осінью і
          зимою дані позиції купують менше і в основному по часу розпродаж.
          <br /> <br />
          Сортування відмінний вибір, щоб сформувати асортимент магазину
          секонд-хенд в залежності від сезонного попиту, розташування магазину і
          т.д.'
        </>
      ),
    },
    {
      question: 'Які бувають сорти, види, категорії секонд-хенд?',
      answer: (
        <>
          Крем- речі в новому стані
          <br /> Екстра-речі в гарному стані <br />1 сорт- речі по яким видно,
          що їх носили <br />
          2-сорт речі з мінімальним браком
          <br /> Ветошь речі з браком{' '}
        </>
      ),
    },
    {
      question: 'Що таке Сток?',
      answer:
        'Сток- це нові речі з магазинів, з розпродажів, поверненнь, або залишків. Можуть бути з біркою або без.',
    },
  ];

  return (
    <AccordionContainer>
      {faqItems.map((item, index) => (
        <AccordionItem key={index}>
          <Question onClick={() => toggleItem(index)}>
            <Number>{String(index + 1).padStart(2, '0')}</Number>
            <Text>{item.question}</Text>
            <Icon>{openItem === index ? <Cross /> : <Plus />}</Icon>
          </Question>
          {openItem === index && <Answer>{item.answer}</Answer>}
        </AccordionItem>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
